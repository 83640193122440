const devolucion = () => (
  <div className="wizard-card">
    <h2>Devolución</h2>
    <ul>
      <li>
        Si se alquila para viernes, sábado o domingo, el artículo se debe
        devolver <b>SIN FALTA</b> el <b>LUNES</b>
      </li>
      <hr />
      <li>
        Si se alquila lunes, martes, miércoles o jueves, se debe devolver{' '}
        <b>SIN FALTA</b> al día siguiente
      </li>
      <hr />
      {/* <li>
        En el caso de <b>NO DEVOLVER</b> el artículo el día asignado se
        descontarán $300 al depósito por el primer día de demora y luego se
        descontarán $100 por cada día que pase. Pasado el tercer día de atraso
        en la entrega, se deberá abonar el importe de otro alquiler equivalente
        al mismo valor del que fue alquilado
      </li>
      <hr /> */}
      <li>Se cobran $300 por cada día de demora los primeros dos días</li>
      <hr />
      <li>Al tercer día sin devolver se cobra un nuevo alquiler completo</li>
      <hr />
      <li className="wizard-message">
        <b>
          Se debe devolver las prendas en las mismas bolsas o fundas de la
          tienda. En caso de no ser así, se retendrá el valor total de la
          garantía.
        </b>
      </li>
    </ul>
  </div>
);

export default devolucion;
