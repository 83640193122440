import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

// api requests go get and save contract
export const getContract = (rentId) => {
    let token = localStorage.getItem('token')
    return axios.get(
        `${REACT_APP_BASE_URL}/get-contract/${rentId}`,
        {
            headers: { 'Authorization': "Token " + token },
            timeout: 16000
        })
}

/**
 * 
 * Sends contract data to backend
 * Returns a promise, error catching has to be managed locally
 * 
 * @param {base64 string} contractData 
 * @param {string} email 
 */

export const saveContract = (contractData, contract) => {
    let token = localStorage.getItem('token')
    return axios.post(`${REACT_APP_BASE_URL}/save-contract`,
        {
            img: contractData,
            contract
        },
        {
            headers: { 'Authorization': "Token " + token }
        })
}