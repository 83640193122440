import React, { Component } from 'react';

export default class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isAuth: false,
      errorMessage: '',
      user: '',
      password: '',
    }
  }

  componentDidMount() {
    this.props.auth.isAuthenticated()
      .then(e => {
        this.setState({
          isAuth: e
        })
      })
  }

  onChangeUser = (e) => this.setState({ user: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  onLogin = () => {
    this.props.auth.handleAuthentication({ username: this.state.user, password: this.state.password })
      .then(() => {
        this.props.auth.isAuthenticated().then(e => {
          this.setState({ errorMessage: '', isAuth: e })
        })
      }).catch(err => this.setState({ errorMessage: 'Usuario o contraseña equivocados' }))
  }

  render() {
    const { children } = this.props
    return (
      <React.Fragment>
        {this.state.isAuth ? children :
          <div className="col-sm-12 col-md-8 offset-md-2 col-lg-4 col-xl-4 offset-lg-4 rent-form form">
            <form>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Usuario"
                  onChange={this.onChangeUser}
                  value={this.state.user} />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Contraseña"
                  onChange={this.onChangePassword}
                  value={this.state.password} />
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="form-control btn btn-primary"
                  value="Login"
                  onClick={this.onLogin}> Login </button>
              </div>
              {this.state.errorMessage
                &&
                <div
                  className="alert alert-warning"
                  style={{ fontSize: '1rem' }}
                >
                  {this.state.errorMessage}
                </div>}
            </form>
          </div>
        }
      </React.Fragment>
    );
  }
}