import React from 'react';

import { prepareContract } from '../utils'

export default function ArticleData(props) {

  if (!props.alquiler) {
    props = { alquiler: {} };
  }
  
  const contract = prepareContract(props.alquiler)

  return (
    <table className="fields">
      <tbody>
        <tr>
          <td>
            <span>Artículo/s:</span>
            <ul className="products_list">
              {contract.items.map(e => <li key={`${e.id}`}><span id="j_idt3:0:art_desc">{e}</span></li>)}
            </ul>
            <div id="prod_obs">OBSERVACIONES DEL ARTÍCULO: {contract.client_observations.map(e => `${e} - `).join('')}</div>
          </td>

          <td rowSpan="2" className="right-border">
            <ul className="leaders">
              <li>
                <span>Nombre:</span><span>{contract.client_name || 'empty'}</span>
              </li>
              <li>
                <span>C.I.:</span><span>{contract.client_ci || 'empty'}</span>
              </li>
              <li>
                <span>Día de reserva</span><span>{contract.created || 'empty'}</span>
              </li>
              <li>
                <span>Día de entrega:</span><span>{contract.pickup_date || 'empty'}</span>
              </li>
              <li>
                <span>Día de devolución</span><span>{contract.return_date || 'empty'}</span>
              </li>
              <li> 
                <span>Cel.</span><span>{contract.client_cel || 'empty'}</span>
              </li>
              <li>
                <span>Dirección:</span><span>{contract.client_add || 'empty'}</span>
              </li>
              <li>
                <span>Mail:</span><span>{contract.client_email || 'empty'}</span>
              </li>
            </ul>
            De conformidad, se lee, otorga y suscribe el presente contrato.
          </td>
        </tr>
        <tr>
          <td className="top-border">
            <ul className="leaders">
              <li>
                <span>Seña del alquiler:</span><span>$ {contract.deposit}</span>
              </li>
              <li>
                <span>Saldo a abonar del alquiler:</span><span>$ {contract.amount_to_be_paid}</span>
              </li>
              <li>
                <span>Garantía al retirar:</span><span>$ {contract.warranty}</span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );
}