import React, { Component } from "react";
import Loader from 'react-loader-spinner';

import BasicWizard from '../wizard/BasicWizard';
import Overlay from '../shared/overlay';
import { getContract } from "../../services/contract";

import './manager.scss';

export default class Manager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rent: null,
            rentId: '',
            error: null,
            loading: false
        }
    }

    onChange = (e) => {
        const newVal = e.target.value;
        if (e !== this.state.rentId) {
            this.setState({ rentId: newVal });
        }
    }

    onClick = () => {
        if (this.state.rentId) {
            this.setState({ error: null, loading: true });
            getContract(this.state.rentId)
                .then(data => {
                    if (data.data.status !== 'Firma pendiente') {
                        this.setState({ loading: false, error: 'El contrato ya ha sido firmado!' })
                    } else {
                        this.setState({ rent: data.data, loading: false })
                    }
                })
                .catch(err => {
                    let error = 'Intentelo nuevamente';
                    if (err.message === "Request failed with status code 404") {
                        error = "El alquiler no existe";
                    }
                    this.setState({ loading: false, error: error })
                })
        }
    }

    render() {

        const rentIdForm = <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 col-xl-6 offset-lg-3 rent-form">
            <p>Ingrese el identificador de un alquiler para continuar con la generación de un contrato!</p>
            <div className="input-group">
                <input
                    type="text"
                    onChange={this.onChange}
                    className="form-control"
                    placeholder="Id de alquiler"
                    aria-label={this.state.rentId}
                    aria-describedby="button-addon2" />
                <div className="input-group-append">
                    <button
                        className="btn btn-outline-secondary"
                        onClick={this.onClick}>Buscar</button>
                    </div>
                </div>
            <p className="text-danger">{this.state.error || this.state.error}</p>
        </div>;

        return (
            <div style={{height: '80%'}}>
                {this.state.rent ? <BasicWizard
                    alquiler={this.state.rent}
                    resetState={() => this.setState({ rent: null })} /> : rentIdForm}
                {!this.state.loading || <Overlay loader={
                    <Loader
                        type='Oval'
                        height='200px'
                        width='200px'
                        color='rgba(207, 50, 121, 1)'
                    />}
                />}
            </div>
        )
    }
}