const requisitos = () => (
  <div className="wizard-card">
    <h2>Requisitos para retirar artículo</h2>
    <ul>
      <li>
        Si es la primera vez que se realiza un alquiler, debe de ser registrado
        como cliente, presentando C.I. y constancia de domicilio
      </li>
      <hr />
      <li>El artículo debe de estar totalmente abonado</li>
      <hr />
      <li>
        Se debe de entregar $500 en <b>EFECTIVO</b> por cada artículo alquilado
        en concepto de depósito en garantía
      </li>
      <hr />
      <li>Se podrá retirar el artículo un día hábil previo al evento</li>
    </ul>
  </div>
);

export default requisitos;
