import React, { Component } from 'react';
import SignatureCanvas from './SignatureCanvas';//'react-signature-pad-wrapper';
import Loader from 'react-loader-spinner';

import Overlay from '../shared/overlay.js';
import { saveContract } from '../../services/contract';
import { prepareContract } from '../contract/utils'
export default class SignaturePad extends Component {

  canvasRef = {};

  constructor(props) {
    super(props);
    this.state = {
      trimmedDataURL: null,
      email: props.email,
      contract: prepareContract(props.contract),
      saveMessage: '',
      loading: false,
    }
  }

  send = () => {
    if (this.canvasRef.isEmpty()) {    
      console.log('Canvas is empty!');  
      this.setState({ saveMessage: <h4 className="msgBanner alert-warning">Por favor firme en el rectangulo de abajo!</h4>, loading: false });
      return;
    }
    const base64Signature = this.canvasRef.toDataURL('image/png');
    this.clear();
    this.setState({ loading: true, saveMessage: '' })
    setTimeout(() => {
      saveContract(base64Signature, this.state.contract)
        .then(res => {
          this.setState({ saveMessage: <h4 className="alert alert-success text-center">Contrato enviado exitosamente!</h4>, loading: false });
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.error) this.setState({ saveMessage: <h4 className="msgBanner"> {err.response.data.error} </h4>, loading: false });
          else this.setState({ saveMessage: <h4 className="msgBanner">Ha ocurrido un error, intentelo nuevamente.</h4>, loading: false });
        })
    }, 0);
  }

  clear = () => {
    this.canvasRef.clear();
  }

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  render() {
    return (
      <React.Fragment>
        <span>
          {this.state.saveMessage}
        </span>
        <div className="row" style={{ marginBottom: '1em'}}>
          <div className='col'>
            <div className="card">
              <div className="card-body">
                <SignatureCanvas
                  className="col"
                  ref={(ref) => {
                    this.canvasRef = ref
                  }}
                  options={{ penColor: 'rgb(66, 133, 244)', minWidth: 1, maxWidth: 3 }}
                  canvasProps={{ height: 50, style: { border: '1px solid black', display: 'block' } }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="float-right">
          <button className="btn btn-warning mr-3" onClick={this.clear}>Limpiar</button>
          <button className="btn btn-primary" onClick={this.send}>Enviar</button>
        </div>


        {!this.state.loading || <Overlay loader={
          <Loader
            type='Oval'
            height='200px'
            width='200px'
            color='rgba(207, 50, 121, 1)'
          />}
        />}

        {/* para mostrar las imagenes
        {this.state.trimmedDataURL
                          ? <img 
                          src={this.state.trimmedDataURL} />
                          : null} */}

      </React.Fragment>

    );
  }
}
