import axios from 'axios';

const { REACT_APP_AUTH_BASE_URL } = process.env;

export default class Auth {
  
  constructor() {
    this.token = localStorage.getItem('token');
    this.expiresAt = localStorage.getItem('expire_date')
    
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.logout = this.logout.bind(this);
    this.setSession = this.setSession.bind(this);
    this.isCurrentTokenValid = this.isCurrentTokenValid.bind(this);
  }
  

  handleAuthentication({ username, password }) {
    return new Promise((resolve, reject) => {
      axios.post(`${REACT_APP_AUTH_BASE_URL}/login/`, { username, password })
        .then(data => {
          this.setSession(data.data.token, data.data.expiry)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  isCurrentTokenValid() {
    return new Promise((resolve, reject) => {
      axios.get(`${REACT_APP_AUTH_BASE_URL}/accounts/me/`, {headers: { 'Authorization': "Token " + this.token }})
        .then(data => {
          resolve(true)
        })
        .catch(err => {
          reject(false)
        })
    })
  }

  async isAuthenticated() {
    if (!this.token || !this.expiresAt) return false;
    else if (new Date().getTime() > new Date(this.expiresAt).getTime()) return false;
    else {
      return await this.isCurrentTokenValid()
    }
  }

  logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('expire_date')
  }

  setSession(idToken, expiresIn) {
    this.token = idToken;
    this.expiresAt = expiresIn;
    localStorage.setItem('token', idToken)
    localStorage.setItem('expire_date', expiresIn)
  }
}