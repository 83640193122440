const deposito = () => (
  <div className="wizard-card">
    <h2>Depósito</h2>
    <ul>
      <li>
        El importe del depósito se reintegrará el mismo día que se reintegra el
        artículo
      </li>
      <hr />
      <li>
        <b>NO</b> se reintegrará el depósito en caso de daños
      </li>
      <hr />
      {/* <li>
        En casos de suciedad extrema se retendrá el depósito hasta que el
        artículo retorne de tintorería para asegurar que el mismo no quedó
        dañado. Si se necesitan hacer limpiezas especiales, se reintegrará solo
        $200 del depósito
      </li> */}
      <li>
        En caso se suciedad extrema, ruedos embarrados, mancha de pinturas, etc.
         Se retendrá el <b>TOTAL</b> del dinero de la garantía.
      </li>
    </ul>
  </div>
);

export default deposito;