import React, { Component } from 'react';

import { prepareContract } from '../utils'

export default class Talon extends Component {

  render() {
    const contract = prepareContract(this.props.alquiler)

    return (
      <React.Fragment>
        <div className="talon" style={{ paddingTop: '5em' }}>
          <div className="row" style={{ border: "0.8px solid black", margin: "5px 100px" }}>
            <div className="col-6">
              <div>
                <span>COD. ALQUILER:</span>
                <span className="float-right font-weight-bold">{contract.id || 'empty'}</span>
              </div>
              <div>
                <span>NOMBRE:</span>
                <span className="float-right font-weight-bold">{contract.client_name || 'Cristina Perez Gomez'}</span>
              </div>
              <div>
                <span>SEÑA DEL ALQUILER:</span>
                <span className="float-right font-weight-bold">$ {contract.deposit || '$0'}</span>
              </div>
              <div>
                <span>SALDO A ABONAR DEL ALQUILER:</span>
                <span className="float-right font-weight-bold">$ {contract.amount_to_be_paid || '$0'}</span>
              </div>
              <div>
                <span>GARANTÍA AL RETIRAR - EN EFECTIVO:</span>
                <span className="float-right font-weight-bold">$ {contract.items.length * 500}</span>
              </div>
            </div>
            <div className="col-6">
              <div>
                <span>FECHA DE RESERVA: </span>
                <span className="float-right font-weight-bold">{contract.created || new Date().toLocaleDateString()}</span>
              </div>
              <div>
                <span>FECHA ENTREGA:</span>
                <span className="float-right font-weight-bold">{contract.pickup_date || new Date().toLocaleDateString()}</span>
              </div>
              <div>
                <span>FECHA DEVOLUCIÓN:</span>
                <span className="float-right font-weight-bold">{contract.return_date || new Date().toLocaleDateString()}</span>
              </div>
              <div>
                <p style={{ margin: '0px 0px -5px 0px' }}><strong>TRAER: CÉDULA Y CONSTANCIA DE DOMICILIO.</strong></p>
                TEL: 27053481 LÁZARO GADEA 905 ESQ. BLVR. ESPAÑA.
                        </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="text-center"><span>LOS MIÉRCOLES NO REALIZAMOS CAMBIOS.</span></p>
            </div>
          </div>
        </div>

      </React.Fragment>

    )
  }
}