const cambiosMen = () => (
  <div className="wizard-card">
    <h2>Cambios en caso de reserva</h2>
    <ul>
      <li>Se pueden realizar solo 5 días hábiles luego de la reserva</li>
      <hr />
      <li>
        En caso de realizar un cambio de fecha, la misma debe de estar dentro de
        los 3 meses posteriores de la reserva original
      </li>
      <hr />
      {/* <li>
        Si se cambia una prenda por una de menor valor se multa el 50% de la
        diferencia. Si se cambia un vestido de $1500 por uno de $1000, este
        último quedará con valor $1250. &nbsp;<b>NO</b> realizamos devolución de
        dinero en caso de cambios por menor valor.
      </li> */}
      <li>Al cambiar una prenda, se mantiene siempre el mayor valor</li>
      <hr />
      <li>
        <b>NO</b> se realizan cambios los martes y miércoles
      </li>
      <hr />
      <li>
        <b>NO</b> se realizan cambios luego de retirar la prenda
      </li>
    </ul>
  </div>
);

export default cambiosMen;
