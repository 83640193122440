import React, { Component } from 'react';

import Logo from './components/shared/logo';
import Manager from './components/manager/manager';
import Auth from './components/Authentication/Auth/auth';
import Login from './components/Authentication/login'

import './public/style.scss';

export default class App extends Component {

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="d-inline-flex p-4 d-sm-flex">
          <Logo />
        </div>
        <div>
          <Login auth={new Auth()} children={<Manager />} />
        </div>
      </div>
    );
  }
}

