const Message = () => (
  <div className="wizard-card">
    <h2>Importante</h2>
    <div className="wizard-message">
    <ul>
      <li>
        Por favor leer a continuación el resúmen del contrato de alquiler antes
        de firmar
      </li>
      <hr />
      <li>
        Este mismo se te enviará por mail luego de firmarlo para que puedas
        tener siempre la información presente
      </li>
      <hr />
      <li>
        No nos hacemos responsables por las personas que no lean dicho
        documento
      </li>
      <hr />
      <li>
        Es de suma importancia chequear la prenda antes de reservarla y antes de
        retirarla para observar el estado de la misma y dejar aclarado con
        anterioridad detalles que pueda tener debido a los usos
      </li>
      <hr />
      <li>
        Luego de retirada la prenda de tienda, no nos hacemos responsables
      </li>
    </ul>
    </div>
  </div>
);

export default Message;
