import React, { Component } from 'react';
import ArticleData from './article/article';

import './contract.scss'

export default class Contract extends Component {

    render() {
        return (
            <div className="datos-alquiler mt-lg-1">
                <h1 className="text-center" style={{ fontFamily: 'NexaLight' }}>Datos de alquiler</h1>
                <div className="col">
                    <div id="content">
                        <ArticleData alquiler={this.props.alquiler} />
                    </div>
                </div>
                <hr style={{ borderTop: 'solid 2px' }} />
            </div>
        )
    }
}
