import React from 'react';

const overlay = (props) => {
    return (
        <div>
            <div style={containerStyle}>
                {props.loader}
            </div>
            <div style={overlayStyle}>
            </div>
        </div>
    )
}

const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    opacity: 0.3,
    backgroundColor: '#000000',
}

const containerStyle = {
    position: 'fixed',
    top: 'calc(50vh - 100px)',
    left: 'calc(50vw - 100px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export default overlay;