import React, { Component } from 'react';

import './logo.scss';

export default class Logo extends Component {
  //logo

  render() {
    return (
      <div className="container">
        <div className="logo">
          RENT THE LOOK
        </div>
      </div>
    );
  }

}