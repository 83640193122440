const reservas = () => (
  <div className="wizard-card">
    <h2>Reservas</h2>
    <ul>
      <li>
        <h3>Efectivo</h3>
      </li>
      {/* <hr/> */}
      <li>Debe abonar el 50% para realizar la reserva</li>
      <hr />
      <li>
        En caso de <b>CANCELACIÓN</b>, NO se devolverá la seña
      </li>
      <hr />
      <li>
        <h3>Crédito y Débito</h3>
      </li>
      {/* <hr/> */}
      <li>Debe abonar el 100% para realizar la reserva</li>
      <hr />
      <li>
        En caso de <b>CANCELACIÓN</b>, el 50% del dinero quedará a favor para
        otro alquiler en los próximos 6 meses
      </li>
    </ul>
  </div>
);

export default reservas;
