import React, { Component } from 'react';

import Cambios from './contract-wiz/Cambios';
import CambiosMen from './contract-wiz/CambiosMen';
import Devolucion from './contract-wiz/Devolucion';
import Requisitos from './contract-wiz/Requisitos';
import Reservas from './contract-wiz/Reservas';
import Deposito from './contract-wiz/Deposito';
import Mensaje from './contract-wiz/Message';

import ReactToPrint from 'react-to-print';

import Talon from '../contract/talon/talon';
import Contract from '../contract/contract';
import Signature from '../signature-pad/signature-pad';

import './wizard-body.scss';

class BasicWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
    };

    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }

  triggerPrint = () => {
    return <button className="btn btn-success">Imprimir talón</button>;
  };

  ContractSign = () => (
    <div>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={this.onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <Contract alquiler={this.props.alquiler} />
      <Signature contract={this.props.alquiler} />
      <ReactToPrint
        trigger={this.triggerPrint}
        content={() => this.componentRef}
      />
      <div style={{ position: 'fixed', bottom: '-1000px' }}>
        <Talon
          ref={(ref) => (this.componentRef = ref)}
          alquiler={this.props.alquiler}
        />
      </div>
    </div>
  );

  womanPages = [
    Mensaje,
    Reservas,
    Cambios,
    Requisitos,
    Devolucion,
    Deposito,
    this.ContractSign,
  ];

  menPages = [
    Mensaje,
    Reservas,
    CambiosMen,
    Requisitos,
    Devolucion,
    Deposito,
    this.ContractSign,
  ];

  pages = this.props.alquiler.store === 1 ? this.womanPages : this.menPages;

  _next() {
    let currentStep = this.state.currentStep;
    // Make sure currentStep is set to something reasonable
    if (currentStep >= this.pages.length - 1) {
      currentStep = this.pages.length - 1;
    } else {
      currentStep = currentStep + 1;
    }

    this.setState({
      currentStep: currentStep,
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 0) {
      currentStep = 0;
      this.props.resetState();
    } else {
      currentStep = currentStep - 1;
    }

    this.setState({
      currentStep: currentStep,
    });
  }

  onClose = () => {
    this.setState({ currentStep: 0 });
    this.props.resetState();
  };

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="wizard-container">
            {this.state.currentStep >= 0 &&
              this.state.currentStep < this.pages.length &&
              this.pages[this.state.currentStep]()}
          </div>
        </div>

        {this.state.currentStep >= 0 &&
          this.state.currentStep < this.pages.length - 1 && (
            <div className="console">
              <button className="btn btn-info btn-lg" onClick={this._prev}>
                Anterior
              </button>
              <button className="btn btn-success btn-lg" onClick={this._next}>
                Siguiente
              </button>
            </div>
          )}
      </React.Fragment>
    );
  }
}

export default BasicWizard;
/**
 * Los pasos son Reservas -> Cambios -> Requisitos -> Devolucion -> Deposito -> Datos de alquiler y firma -> Loader -> Exito!
 */
